<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="客户">
                <a-input v-model="queryParam.client" placeholder="请输入客户姓名或手机号"/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="经纪人">
                <a-input v-model="queryParam.broker" placeholder="请输入经纪人姓名或手机号"/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">                    
              <a-form-item
                label="状态">
                <a-select
                  show-search
                  placeholder="请选择"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  v-decorator="[
                    'saleStatus'
                  ]"
                  @change="handleSelectChange"
                  v-model="queryParam.houseId"
                  allowClear>
                  <a-select-option :value="item.id" v-for="(item,index) in housesList" :key="index">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="findByKeyword">查询</a-button>
                <a-button style="margin-left: 8px" @click="tableReset">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="file-word" @click="handleAdd">导出</a-button>
        <a-button type="danger" icon="delete" @click="handleDelete">删除</a-button>
      </div>
      <a-modal
        title="刪除"
        :visible="del.visible"
        :confirm-loading="del.confirmLoading"
        @ok="handleDeleteOk"
        @cancel="handleDeleteCancel"
      >
        <p>{{ del.ModalText }}</p>
      </a-modal>

      <s-table
        ref="table"
        size="default"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data="loadData"
        :alert="true"
        :rowSelection="rowSelection"
        showPagination="auto"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="status" slot-scope="text">
          <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">查看</a>
          </template>
        </span>
      </s-table>
      <step-by-step-modal ref="modal" @ok="handleOk" />

      <!-- evidence info -->
      <a-drawer
        :title="drawer ? '流水号:'+ drawer.id : '流水号: -'"
        placement="right"
        width="600px"
        :visible="visible"
        :after-visible-change="afterVisibleChange"
        @close="onClose"
      >
        <a-card :bordered="false">
          <a-descriptions :title="'楼盘名称：' + (drawer.task ? drawer.task.houseName : '-')"></a-descriptions>
          <a-descriptions title="到访信息" class="evidence" v-if="drawer.status===9 || drawer.status===10">
            <a-descriptions-item label="客户姓名" span="3">{{ 
              drawer.client ? drawer.client.name : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="客户手机号" span="3">{{
              drawer.client ? drawer.client.phone : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="经纪人" span="3">{{
              drawer.broker ? drawer.broker.name : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="经纪人手机号" span="3">{{
              drawer.broker ? drawer.broker.phone : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="置业顾问" span="3">{{
              drawer.consultant ? drawer.consultant.name : '-' 
            }}</a-descriptions-item>
            <a-descriptions-item label="驻场人员" span="3">{{ 
              drawer.auditUser ? drawer.auditUser.name: '-' 
            }}</a-descriptions-item>
            <a-descriptions-item label="需求面积" span="3">{{
              `${drawer.client.areaStart || '-'} - ${drawer.client.areaEnd || '-'} m²` || '-' 
            }}</a-descriptions-item>
            <a-descriptions-item label="需求业态" span="3">{{
              drawer.client.propertyType || '-' 
            }}</a-descriptions-item>
            <a-descriptions-item label="意向强度" span="3"><a-rate :default-value="drawer.client ? drawer.client.intention : '0'" disabled /></a-descriptions-item>
            <a-descriptions-item label="报备申请时间" span="3">{{ 
              drawer.createTime || '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="预定到访时间" span="3">{{ 
              drawer.appointment || '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="到访时间" span="3">{{
              drawer.acceptTime || '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="备注信息" span="3">{{
              drawer.remark || '-' 
            }}</a-descriptions-item>
            <a-descriptions-item label="到访证据" span="3" v-if="drawer.evidence">
              <a-card hoverable style="width: 120px" v-for="item in drawer.evidence" :key="item.id" class="inlineBlock">
                <!-- <img
                  slot="cover"
                  alt="example"
                  :src="$store.state.oss.host + item.path"
                /> -->
                <media :file="item" />
              </a-card>
            </a-descriptions-item>
          </a-descriptions>
          <a-divider style="margin-bottom: 32px" v-if="drawer.status!==9 && drawer.status!==10"/>
          <a-descriptions title="报备信息" v-if="drawer.status!==9 && drawer.status!==10">
            <a-descriptions-item label="客户姓名" span="3">{{ 
              drawer.client ? drawer.client.name : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="客户手机号" span="3">{{ 
              drawer.client ? drawer.client.phone : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="经纪人" span="3">{{ 
              drawer.broker ? drawer.broker.name : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="经纪人手机号" span="3">{{ 
              drawer.broker ? drawer.broker.phone : -''
            }}</a-descriptions-item>
            <a-descriptions-item label="需求面积" span="3">{{ 
              drawer.client ? `${drawer.client.areaStart || '-'} - ${drawer.client.areaEnd || '-'} m²` || '-' : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="需求业态" span="3">{{ 
              drawer.client ? drawer.client.propertyType : '-' 
            }}</a-descriptions-item>
            <a-descriptions-item label="意向强度" span="3"><a-rate :default-value="drawer.client ? drawer.client.intention : '0'" disabled /></a-descriptions-item>
            <a-descriptions-item label="报备申请时间" span="3">{{ 
              drawer.createTime ? drawer.createTime : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="预定到访时间" span="3">{{ 
              drawer.appointment ? drawer.appointment : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="备注信息" span="3">{{ 
              drawer.remark ? drawer.remark : '-' 
            }}</a-descriptions-item>
          </a-descriptions>
          <a-divider style="margin-bottom: 32px"/>
          <a-descriptions title="日志" class="log">
            <!-- <a-descriptions-item label="张经纪" span="3"><span>上传证据链</span><span>2020-20-13 13:33:25</span></a-descriptions-item>
            <a-descriptions-item label="王驻场" span="3"><span>到访扫码</span><span>2020-20-13 13:33:25</span></a-descriptions-item>
            <a-descriptions-item label="王驻场" span="3"><span>审核通过</span><span>2020-20-13 13:33:25</span></a-descriptions-item>
            <a-descriptions-item label="张经纪" span="3"><span>发起报备</span><span>2020-20-13 13:33:25</span></a-descriptions-item> -->
          </a-descriptions>
        </a-card>
      </a-drawer>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import moment from 'moment'
import { STable, Ellipsis, Media } from '@/components'
import StepByStepModal from './modules/StepByStepModal'
import CreateForm from './modules/CreateReport'
import { queryReports, checkOrder, deleteReports } from '@/api/reports'
import { housesAll } from '@/api/houses'
const columns = [
  {
    title: '楼盘名称',
    dataIndex: 'houseName'
  },
  {
    title: '客户',
    dataIndex: 'client.name'
  },
  {
    title: '经纪人',
    dataIndex: 'broker.name'
  },
  {
    title: '置业顾问',
    dataIndex: 'broker.name'
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    sorter: true
  },
  {
    title: '报备时间',
    dataIndex: 'updateTime',
    sorter: true
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

const statusMap = {
  0: {
    status: 'default',
    text: '待审批'
  },
  1: {
    status: 'processing',
    text: '已报备'
  },
  '-1': {
    status: 'default',
    text: '已否决'
  },
  '-2': {
    status: 'default',
    text: '已过到访保护期'
  },
  '-3': {
    status: 'default',
    text: '未到访已作废'
  },
  9: {
    status: 'processing',
    text: '已到访'
  },
  10: {
    status: 'processing',
    text: '已认购'
  }
}

const evidence = [
  {
    url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
  },
  {
    url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
  },
  {
    url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
  },
  {
    url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
  },
  {
    url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
  },
  {
    url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
  },
  {
    url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
  },
  {
    url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
  },
  {
    url: 'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png'
  }
]
export default {
  name: 'TableList',
  components: {
    STable,
    Ellipsis,
    CreateForm,
    StepByStepModal,
    Media
  },
  data() {
    this.columns = columns
    this.evidence = evidence
    return {
      // create model
      requestParameters: {},
      relevance: null,
      visible: false,
      confirmLoading: false,
      mdl: null,
      // 高级搜索 展开/关闭
      advanced: false,
      // 批量删除 modal
      del: {
        // ModalText: this.relevance ? '会同时删除已关联的签约单，是否继续？' : '您要删除这些项目吗 是否继续',
        ModalText: '您要删除这些项目吗 是否继续？',
        visible: false,
        confirmLoading: false
      },
      // 查询参数
      queryParam: {
        client: '',
        broker: ''
      },
      search: {
        visible: false
      },
      drawer: [],
        // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let sorterStr = ''
        if (parameter.sortOrder === 'descend') {
          sorterStr = `${parameter.sortField},desc`
        } else if (parameter.sortOrder === 'ascend') {
          sorterStr = `${parameter.sortField}`
        } else {
          sorterStr = `createTime,desc`
        }
        const requestParameters = {
          page: parameter.pageNo - 1,
          size: parameter.pageSize,
          sort: sorterStr,
          houseId: this.houseId
        }
        return queryReports(requestParameters).then(res => {
          const result = {
            data: res.data.content,
            pageNo: res.data.number,
            pageSize: res.data.size,
            totalCount: res.data.totalElements,
            totalPages: res.data.totalPages
          }
          // return result
            if (this.search.visible) {
              if (this.queryParam.client) {
                this.queryParam.client.length === 11 && /^1\d{10}$/.test(this.queryParam.client) 
                ? requestParameters.clientPhone = this.queryParam.client 
                : requestParameters.clientName = this.queryParam.client
              }
              if (this.queryParam.broker) {
                this.queryParam.broker.length === 11 && /^1\d{10}$/.test(this.queryParam.broker) 
                ? requestParameters.brokerPhone = this.queryParam.broker 
                : requestParameters.brokerName = this.queryParam.broker
              }
              if (this.queryParam.houseId) {
                  requestParameters.houseId = this.queryParam.houseId
              }
              return queryReports(requestParameters).then(res=>{
                  const findResult = {
                  data: res.data.content,
                  pageNo: res.data.number,
                  pageSize: res.data.size,
                  totalCount: res.data.totalElements,
                  totalPages: res.data.totalPages
                }
                 console.log(res, 'resgggggggg')
                return findResult
              })
            } else {
            return result
            }
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      housesList: []
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange
      }
    },
    houseId() {
      return this.$store.state.house.houseId
    },
    userId() {
      return this.$store.state.user.info.id
    }
  },
  methods: {
    getHouseList () {
      housesAll().then(res=>{
         this.housesList = res._embedded.houses
      })
    },
    handleAdd() {
      this.$notification['success']({
        message: '导出成功',
        description: '成功导出Exel.'
      })
    },
    // 批量删除
    handleDelete() {
      const len = this.selectedRows.length
      if (len <= 0) {
        this.$message.warning('请选择要删除的项', 2)
      } else {
         let ids = ''
         const paramIds = {}
        //  let come = null
          this.selectedRows.forEach(item => {
           paramIds.reportId = item.id
           ids += item.id + ','
              checkOrder(paramIds).then(res => {
                 this.relevance = res.data
                if (this.relevance === true) {
                    this.del.ModalText = '会同时删除已关联的签约单，是否继续？'
                  }
            })

         })
        if (ids.length > 0) {
          this.requestParameters.reportIds = ids.substr(0, ids.length - 1)
        }
        this.del.visible = true
      }
    },
    handleDeleteOk(e) {
      this.del.ModalText = '将在两秒后删除此项'
      this.del.confirmLoading = true
      this.relevance = false
      setTimeout(() => {
        this.del.confirmLoading = false
        // this.del.ModalText = '您要删除这些项目吗 是否继续？'
        this.confirmLoading = true
        if (this.del.visible !== false) {
          deleteReports(this.requestParameters).then(res => {
            this.$refs.table.refresh()
              this.$message.success('删除成功', 2)
              this.del.visible = false
              this.confirmLoading = false
              this.selectedRows.splice(0, this.selectedRows.length)
          }).catch(() => {
              this.confirmLoading = false
          })
        } else {
          this.del.visible = false
          this.confirmLoading = false
        }
      }, 2000)
    },
    handleDeleteCancel(e) {
      this.relevance = false
      this.del.ModalText = '您要删除这些项目吗 是否继续？'
      this.del.visible = false
    },
    handleEdit (record) {
      this.drawer = record
      console.log(this.drawer, '楼盘')
      // this.report.visible = true
      this.visible = true
    },
    checkOut (record) {

    },
    handleOk() {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          if (values.id > 0) {
            // 修改 e.g.
            new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve()
              }, 1000)
            }).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()

              this.$message.info('修改成功')
            })
          } else {
            // 新增
            new Promise((resolve, reject) => {
              setTimeout(() => {
                resolve()
              }, 1000)
            }).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()

              this.$message.info('新增成功')
            })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    },
    // report info
    afterVisibleChange (val) {
    },
    onClose () {
      // this.report.visible = false
      this.visible = false

    },
    findByKeyword () {
      this.search.visible = true
      this.$refs.table.refresh()
    },
    tableReset () {
      this.search.visible = false
      this.queryParam = {}
      this.$refs.table.refresh()
    },
    handleSelectChange (value) {
      this.queryParam.houseId = value
    },
     filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }
}
}
</script>

<style lang="less" scoped>
.log {
  /deep/ .ant-descriptions-item-content {
    span:first-child {
      font-weight: 700;
    }
    span:last-child {
      font-weight: 700;
      margin-left: 50px;
    }
  }
  /deep/ .ant-descriptions-item-label {
    font-weight: 700;
  }
}
.evidence {
  /deep/ .ant-card {
    width: 120px;
    display: inline-block;
    vertical-align: text-top;
    margin-right: 20px;
    margin-top: 20px;
  }
}
</style>

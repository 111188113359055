import request from '@/utils/request'

const reportsApi = {
  // get reports info
  base: '/api/v1',
  reports: '/api/v1/rest/reports',
  query: '/api/v1/report/query',
  findByKeyword: '/api/v1/rest/reports/search/findByKeyword',
  findByStatus: '/api/v1/rest/reports/search/findByStatus',
  findReportsQuery: '/api/v1/report/query'
}

/**
 * list
 * @param params { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function reports (params) {
  let url = reportsApi.reports
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * id
 * @param {*} params { id: '' }
 * @returns {*}
 */
export function findIdRrport (params) {
  return request({
    url: reportsApi.reports + `/${params.id}`,
    method: 'get'
  })
}
/**
 * add
 * @param params { name: '', developer: '', area: '', type: '' , time }
 * @returns {*}
 */
export function addReports (params) {
  return request({
    url: reportsApi.reports,
    method: 'post',
    data: params
  })
}

/**
 * edit
 * @param params { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function editReports (params) {
  return request({
    url: reportsApi.reports + `/${params.id}`,
    method: 'patch',
    data: params
  })
}

/**
 * del
 * @param params { id: '' }
 * @returns {*}
 */
export function delReports (params) {
  return request({
    url: reportsApi.reports + `/${params.id}`,
    method: 'delete'
  })
}

/**
 * delete
 * @param params { id: '' }
 * @returns {*}
 */
export function deleteReports (params) {
  return request({
    url: reportsApi.base + `/report/delete`,
    method: 'get',
    params
  })
}
/**
 * rearch order
 * @param params { id: '' }
 * @returns {*}
 */
export function checkOrder (params) {
  return request({
    url: reportsApi.base + `/report/checkOrder`,
    method: 'get',
    params
  })
}

/**
 * find
 * @param params { status: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findReportsByStatus (params) {
  let url = reportsApi.findByStatus
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * find
 * @param params { status: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function queryReports (params) {
  let url = reportsApi.query
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * search
 * @param params { keyword: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findReports (params) {
  let url = reportsApi.findByKeyword
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * search
 * @param params { id: '' }
 * @returns {*}
 */
export function findByIdReports (params) {
  return request({
    url: reportsApi.reports + `/${params.id}`,
    method: 'get'
  })
}

/**
 * reports
 * @param params { id: '', name: '' }
 * @returns {*}
 */
export function inReports (params) {
  return request({
    url: reportsApi.reports + `/${params.id}/${params.name}`,
    method: 'get'
  })
}

/**
 * reports report
 * @param params id
 * @returns {*}
 */

export function reportConsultant(id) {
  const url = reportsApi.reports + `/${id}/consultant`
  return request({
    url,
    method: 'get'
  })
}

/**
 *report search 
 @param params { brokerName:'', brokerPhone:'', clientName:'', clientPhone:''}
 @returns {*}
 */
export function findReportsQuery(params) {
  let url = reportsApi.findReportsQuery
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}
